import React, { useState, useEffect } from 'react'
import { useGeoFetch } from '../../../components/GeoProvider'
import GeoProvider from '../../../components/GeoProvider'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import styles from './FluSurveillance.module.scss'
import DashboardHeader from './DashboardHeader'

import UHCCoverageMap from './UHCCoverageMap'
import HomeIndicatorTable from './HomeIndicatorTable'
import dataOptions from '../FluSurveillance/dataDisplayOptions'
import dataOptionsRespiratory from '../Respiratory/dataDisplayOptions'
import Spinner from '../../../components/Loader/Spinner'

import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'

import { startDateHome, endDate } from '../dates'
const Surveillance = () => {

  const entity = 'surveillance'
  const [natsAll, setAllNATs] = useState()
  const [natsResAll, setAllResNATs] = useState()


  const { user } = useAuthState()
  const { data: natsAllData, error: natAllDataError } = useGeoFetch(
    startDateHome,
    endDate,
    entity,
    'NAT/Flu?identifier=NAT'
  )

  useEffect(() => {
    if (natsAllData !== undefined) {
      setAllNATs(natsAllData)
    
    }
  }, [natsAllData])

  const { data: natsAllResData, error: natAllResDataError } = useGeoFetch(
    startDateHome,
    endDate,
    entity,
    'NAT/Respiratory?identifier=NAT'
  )

  useEffect(() => {
    var logObject = {
      dashboard_url: window.location.href,
      dashboard_name: 'Surveillance Home',
      indicator_name: 'Surveillance Home',
      geo_name: '',
      geo_type: '',
      user_name: user.username,
      email: user.email,
    }
    if (natsAllResData !== undefined) {
      activityLog(logObject)
      setAllResNATs(natsAllResData)
    }
  }, [natsAllResData, user.username, user.email])

  if (natAllDataError || natAllResDataError) {
    return (
      <Layout>
        <Content></Content>
      </Layout>
    )
  }
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
         
          <div className={styles.body}>
            <GeoProvider>
              {natsAll !== undefined && natsResAll !== undefined ? (
                <div>
                  {natsAll !== undefined && natsResAll !== undefined ? (
                    <DashboardHeader
                      nationalStats={natsAll}
                      nationalResStats={natsResAll}
                    />
                  ) : (
                    <div></div>
                  )}
                  {natsAll !== undefined ? (
                    <HomeIndicatorTable
                      data={null}
                      nationalStats={natsAll}
                      name="Flu Indicator Table"
                      dataDisplayOptions={dataOptions}
                    />
                  ) : (
                    <div></div>
                  )}
                  {natsResAll !== undefined ? (
                    <HomeIndicatorTable
                      data={null}
                      nationalStats={natsResAll}
                      name="Respiratory Indicator Table"
                      dataDisplayOptions={dataOptionsRespiratory}
                    />
                  ) : (
                    <div></div>
                  )}
                  <UHCCoverageMap></UHCCoverageMap>
                </div>
              ) : (
                <Spinner />
              )}
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Surveillance
