export default function MapLegend(props) {
  return (
    <svg
      viewBox="0 0 120 340"
      height="350"
      width="120"
      align="center"
      margin-top="0"
      {...props}
    >
      <rect
        x="10"
        y="270"
        width="30"
        height="30"
        fill="#CCDAFF"
        id="ILI_V_13"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="240"
        width="30"
        height="30"
        fill="#B5C4EC"
        id="ILI_V_12"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="210"
        width="30"
        height="30"
        fill="#9EAFDA"
        id="ILI_V_11"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="180"
        width="30"
        height="30"
        fill="#879BC8"
        id="ILI_V_10"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="150"
        width="30"
        height="30"
        fill="#7086B6"
        id="ILI_V_9"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="120"
        width="30"
        height="30"
        fill="#5973A4"
        id="ILI_V_8"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="90"
        width="30"
        height="30"
        fill="#426093"
        id="ILI_V_7"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="60"
        width="30"
        height="30"
        fill="#284D82"
        id="ILI_V_6"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="30"
        width="30"
        height="30"
        fill="#003C71"
        id="ILI_V_5"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="0"
        width="30"
        height="30"
        fill="#03234F"
        id="ILI_V_5"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="10"
        y="310"
        width="30"
        height="30"
        fill="#D9D8D8"
        id="ILI_V_4"
        className="LegendColorCodes"
      ></rect>
      <text x="50" y="290" className="legend-label">
        10%
      </text>
      <text x="50" y="260" className="legend-label">
        20%
      </text>
      <text x="50" y="230" className="legend-label">
        30%
      </text>
      <text x="50" y="200" className="legend-label">
        40%
      </text>
      <text x="50" y="170" className="legend-label">
        50%
      </text>
      <text x="50" y="140" className="legend-label">
        60%
      </text>
      <text x="50" y="110" className="legend-label">
        70%
      </text>
      <text x="50" y="80" className="legend-label">
        80%
      </text>
      <text x="50" y="50" className="legend-label">
        90%
      </text>
      <text x="50" y="20" className="legend-label">
        100%
      </text>
      <text x="50" y="330" className="legend-label">
        No Data
      </text>
    </svg>
  )
}
