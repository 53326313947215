import { requiresAuth } from '../components/AuthProvider'

import Home from './Home'
import Login from './Login'
import OneHealthcareIdLogin from './OneHealthcareIdLogin'
import ActiveDirectoryLogin from './ActiveDirectoryLogin'
import LoginRedirect from './LoginRedirect'
import ActiveDirectoryLoginRedirect from './ActiveDirectoryLoginRedirect'
import SignOut from './SignOut'
import ActiveDirectorySignOut from './ActiveDirectorySignOut'
import Profile from './Profile'
import AccessAbort from './AccessAbort'
import NoAccess from './NoAccess'
import NotFound from './NotFound'
import Raredisease from './RareDisease/RarediseaseDetails'
import Dashboard from './Dashboard'
import Vulnerability from './Vulnerability/index'
import FluTabs from './Flu/FluTabs'
import FluDetails from './Flu/FluTabs/FluDetails'
import FluSurveillance from './Surveillance/FluSurveillance'
import HRRMap from './Forecasts/HRR'
import ForecastDashboard from './Forecasts/Home'
import Feedback from './About/Feedback'
import AdminPages from './AdminPages'
import UsersPage from './AdminPages/Users'
import SystemNotificationsPage from './AdminPages/SystemNotifications'
import AddEditUser from './Admin/Users/AddEditUser'
import UserActivity from './Admin/UserActivity'
import Subscriptions from './Admin/Subscriptions/AddSubs'
import AddSubscription from './Admin/Subscriptions/AddEditSubscription'
import Emails from './Admin/Emails'
import Surveillance from './Surveillance/Home'
import UserSubscription from './Subscriptions/ViewSubscription'
import AddSubscriptionUser from './Subscriptions/AddEditSubscription'
import PridictionDashboard from './DiseasePrediction/Dashboard'
import RiskCalculator from './RiskCalculator'
import CovidPharma from './CovidPharma'
import CovidStateDashboard from './CovidPharma/CovidStateDashboard'
import CovidGeoDashboard from './CovidPharma/CovidGeoDashboard'
import FluMeter from './FluMeter'
import HeatMap from './HeatMap'
import FluTrend from './FluTrend'
import AccuracyTrend from './AccuracyTrend'
import SeasonTrend from './SeasonTrend'
import FluVaccination from './FluVaccination'
import FluVaccinationDetails from './FluVaccinationDetails'
import Respiratory from './Surveillance/Respiratory'
import ModelTabs from './About/ModelTab'
import ViewProfile from '../pages/Subscriptions/ViewSubscription'
import ImageGenerate from './Dashboard/ImageGenerate'
import { FEEDBACK_LINK } from '../utils/constants'
const adminAccounts = ['Admin']
const otherAccounts = ['Basic', 'Power User']
const specialAccounts = ['Special User']
const allAccounts = [...otherAccounts, ...adminAccounts, ...specialAccounts]
const phiPii = ['Admin', 'Power User']
const allEnvironments = ['local', 'stage', 'prod']

const Pages = [
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: Home,
    label: 'Home',
    path: '/',
    routes: [
      {
        isHidden: false,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(Profile),
        path: '/profile',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(UserSubscription),
        label: 'Subscriptions',
        path: '/subscriptions',
        routes: [
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(AddSubscriptionUser),
            label: 'Add Subscription',
            path: '/add',
          },
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(Dashboard),
        label: 'Home',
        path: '/Home',
        routes: [],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(ImageGenerate),
        label: '',
        path: '/ImageGenerate',
        routes: [],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(ForecastDashboard),
        label: 'Forecasts',
        path: '/forecasts',
        routes: [
          
          
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(ForecastDashboard),
            label: 'Home',
            path: '/forecasts',
            isHidden: false,
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluTabs),
            label: 'Influenza level',
            path: '/Flumap',
            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluDetails),
            label: 'Flu Charts',
            path: 'flu/:area/:loc',
            routes: [],
          },
          
          { authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(CovidPharma),
            label: 'Covid Incidence',
            path: '/CovidDashboard',
            isHidden: false,
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(HRRMap),
            label: 'Covid-19 Weekly Hospitalization',
            path: '/hrrmap',
            routes: [],
          },

          
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(PridictionDashboard),
        label: 'Disease Prediction',
        path: '/DiseasePrediction',
        routes: [
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(PridictionDashboard),
            label: 'Home',
            path: '/home',
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: phiPii,
            envAccess: allEnvironments,
            component: requiresAuth(Vulnerability),
            label: 'Vulnerability',
            path: '/Vulnerability',
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: phiPii,
            envAccess: allEnvironments,
            component: requiresAuth(Raredisease),
            label: 'Rare Disease',
            path: '/RareDisease',
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: RiskCalculator,
            label: 'Cardiac Risk Calculator',
            path: '/RiskCalculator',
            routes: [],
          },
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(FluSurveillance),
        label: 'Surveillance',
        path: '/Surveillance',

        routes: [
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(Surveillance),
            label: String.raw`Home`,
            path: '/Home',

            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluSurveillance),
            label: String.raw`Flu`,
            path: '/flu',

            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(Respiratory),
            label: String.raw`Respiratory`,
            path: '/respiratory',

            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluSurveillance),
            path: '/covid',
            label: 'Covid-19',
            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluSurveillance),
            path: '/pasc',
            label: 'PASC',
            routes: [],
          },
        ],
      },
    
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(FluVaccination),
        label: 'Vaccinations',
        path: '/Vaccinations',
      
        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluVaccination),
           
            path: '/fluvaccination',
            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluVaccinationDetails),
            label: 'Flu Vaccination Details',
            path: ':area',
          },
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(ModelTabs),
        label: 'About Models',
        path: '/about',
        
        routes: [
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(ModelTabs),
            label: 'Model',
            path: '/model',
            isHidden: true,
            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(ModelTabs),
            label: String.raw`Model Information`,
            path: '/model#information',

            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(ModelTabs),
            label: String.raw`Model Accuracy`,
            path: '/model#accuracy',

            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: Feedback,
            externalLink: FEEDBACK_LINK,
            path: '/feedback',
            label: String.raw`Feedback & 
Suggestions`,
            new: false,
            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(ViewProfile),
            label: 'ViewProfile',
            path: '/Viewprofile',

            routes: [],
          },
        ],
      },
      {
        authorizedRoles: adminAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(UsersPage, adminAccounts),
        label: 'Admin',
        path: '/admin',
        allowedRoles: adminAccounts,
        routes: [
          {
            isHidden: true,
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(Emails, adminAccounts),
            label: 'Emails',
            path: '/emails',
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(UsersPage, adminAccounts),
            label: 'Users',
            path: '/users',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(AddEditUser, adminAccounts),
                label: 'Add User',
                path: '/add',
              },
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(UsersPage, adminAccounts),
                label: 'View User',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    component: requiresAuth(UsersPage, adminAccounts),
                    label: 'Edit User',
                    path: '/edit',
                  },
                ],
              },
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(SystemNotificationsPage, adminAccounts),
            label: 'System Notifications',
            path: '/systemnotifications',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(
                  SystemNotificationsPage,
                  adminAccounts
                ),
                label: 'Add System Notifications',
                path: '/add',
              },
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(SystemNotificationsPage, adminAccounts),
                label: 'View System Notifications',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    component: requiresAuth(
                      SystemNotificationsPage,
                      adminAccounts
                    ),
                    label: 'Edit System Notifications',
                    path: '/edit',
                  },
                ],
              },
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(Subscriptions, adminAccounts),
            label: 'Subscriptions',
            path: '/subscriptions',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(AddSubscription, adminAccounts),
                label: 'Add Subscription',
                path: '/add',
              },
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(UserActivity, adminAccounts),
            label: 'Users Activity',
            path: '/usersactivity',
          },
        ],
      },
      {
        authorizedRoles: adminAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(AdminPages, adminAccounts),
        label: 'AdminPages',
        path: '/adminpage',
        allowedRoles: adminAccounts,    
        isHidden: true,    
        routes: [
         
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(UsersPage, adminAccounts),
            label: 'Users',
            path: '/users',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(UsersPage, adminAccounts),
                label: 'Add User',
                path: '/add',
              },                            
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(UsersPage, adminAccounts),
                label: 'View User',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    component: requiresAuth(UsersPage, adminAccounts),
                    label: 'Edit User',
                    path: '/edit',
                  },
                ],
              },
             
            
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(SystemNotificationsPage, adminAccounts),
            label: 'System Notifications',
            path: '/systemnotifications',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(
                  SystemNotificationsPage,
                  adminAccounts
                ),
                label: 'Add System Notifications',
                path: '/add',
              },
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                component: requiresAuth(SystemNotificationsPage, adminAccounts),
                label: 'View System Notifications',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    component: requiresAuth(
                      SystemNotificationsPage,
                      adminAccounts
                    ),
                    label: 'Edit System Notifications',
                    path: '/edit',
                  },
                ],
              },
            ],
          },
        ],
      },
           
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(CovidPharma),
        label: 'CovidDashboard',
        path: '/CovidStateDashboard',
        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(CovidStateDashboard),
            label: 'State View',
            path: ':area/:loc',
          },
        ],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(CovidPharma),
        label: 'CovidDashboard',
        path: '/CovidMSADashboard',

        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(CovidGeoDashboard),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc',
          },
        ],
      },
    ],
  },  

  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: FluMeter,
    path: '/flumeter',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: HeatMap,
    path: '/heatmap',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: FluTrend,
    path: '/flutrend',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: AccuracyTrend,
    label: 'Flu Accuracy Comparison',
    path: 'AccuracyTrend/:area/:loc',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: SeasonTrend,
    label: 'Season Influenza Pattern',
    path: 'SeasonTrend/:area/:loc',

  },
 

  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: Login,
    path: '/login',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: OneHealthcareIdLogin,
    path: '/ohid-login',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: ActiveDirectoryLogin,
    path: '/ad-login',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: LoginRedirect,
    path: '/oauth2/login-redirect',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: ActiveDirectoryLoginRedirect,
    path: '/msid/login-redirect',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: SignOut,
    path: '/signout',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: ActiveDirectorySignOut,
    path: '/ad-signout',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: NoAccess,
    path: '/access-denied',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: AccessAbort,
    path: '/accessabort',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: NotFound,
    path: '*',
  },
]

export default Pages
