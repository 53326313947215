import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import styles from './DashboardHeader.module.scss'
// import Icon from './../../../components/Icon'
import RiskIcon from './../../../components/RiskIcon'
import dataOptions from '../FluSurveillance/dataDisplayOptions'
import dataOptionsRespiratory from '../Respiratory/dataDisplayOptions'

import getPositiveMethod from './../../../utils/getCountingMethod'

// const getHydratedData = (data, title, padding) => {
//   return (
//     <div className={styles.dateWeek} style={{ paddingLeft: padding }}>
//       <div>
//         <span className={styles.headerSpan}>{data.title}</span>
//       </div>
//       <span className={styles.dataSpan}>
//         <strong>{data.level}</strong>{' '}
//         {data.level == 'High' ? (
//           <Icon size="12px" type="arrow_wtail_bold_up" color="red" />
//         ) : (
//           ''
//         )}
//         {data.level == 'Low' ? (
//           <Icon size="12px" type="arrow_wtail_bold_down" color="green" />
//         ) : (
//           ''
//         )}
//         {'   '} from {'   '}
//         {data.lastweekdate}
//       </span>
//       <br></br>
//       <span className={styles.sourceSpan}>Source: {data.source}</span>
//     </div>
//   )
// }
const HeaderTable = (data, indicator) => {
  let finalValue = data.data[data.indicator]
  return (
    <div className={styles.dateWeek}>
      <div>
        <span className={styles.headerSpan}>{finalValue.indicator}</span>
      </div>
      <span className={styles.dataSpan}>
        <strong>
          {getPositiveMethod(finalValue.changePercentage.toFixed(2))}
        </strong>
        {' %  '}
        <RiskIcon
          size="14px"
          dataValue={finalValue.changePercentage.toFixed(2)}
        />
        {/* {finalValue.changeLevel === 1 ? (
          <Icon size="14px" type="arrow_wtail_bold_up" color="red" />
        ) : (
          ''
        )}
        {finalValue.changeLevel === 2 ? (
          <Icon size="14px" type="arrow_wtail_bold_down" color="green" />
        ) : (
          ''
        )} */}
        {'   '} from {'   '}
        {moment(finalValue.lastWeekDate).format('MMM DD, YYYY')}
      </span>
      <br></br>
      <span>
        Source:<span className={styles.sourceSpan}> {'Claims Data'}</span>
      </span>
    </div>
  )
}
const DashboardHeader = (data, nationalStats, nationalResStats) => {
  const history = useHistory()
  let mapNatsData = null
  var natData = data.nationalStats
  const natsDataForDisplayDate = Object.entries(
    natData[Object.keys(natData).pop()]
  )
  mapNatsData = natsDataForDisplayDate.map((nat) => {
    return {
      value: nat[1],
      name: nat[0],
      ...nat[1],
    }
  })

  let mapNatsResData = null
  var natResData = data.nationalResStats
  const natsResDataForDisplayDate = Object.entries(
    natResData[Object.keys(natResData).pop()]
  )
  mapNatsResData = natsResDataForDisplayDate.map((nat) => {
    return {
      value: nat[1],
      name: nat[0],
      ...nat[1],
    }
  })
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h4>Surveillance</h4>
        <p>You can find the latest surveillance data on Flu & Respiratory</p>
      </div>
      <div className={styles.blankPanel}>{'  '}</div>
      <div className={styles.heading1Panel}>
        <h4>
          <span>Flu</span>
          <span
            className={styles.viewMore}
            onClick={() => {
              history.push(`/surveillance/flu`)
            }}
          >
            View More
          </span>
        </h4>
      </div>
      <div className={styles.heading2Panel}>
        <h4>
          <span>Respiratory</span>
          <span
            className={styles.viewMore}
            onClick={() => {
              history.push(`/surveillance/Respiratory`)
            }}
          >
            View More
          </span>
        </h4>
      </div>
      <div className={styles.fluPanel}>
        {dataOptions.map((val) => {
          return (
            <HeaderTable data={mapNatsData[0].value} indicator={val.label} />
          )
        })}
      </div>
      <div className={styles.respiratoryPanel}>
        {dataOptionsRespiratory.map((val) => {
          return (
            <HeaderTable data={mapNatsResData[0].value} indicator={val.label} />
          )
        })}
      </div>
    </div>
  )
}

export default DashboardHeader
