import colorAxisVulIndex from './colorAxisIndex'

const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'state_percentage',
        tagKey: 'state_percentage',
        tagValue: 'state_percentage',
        stateCount: 'total_state_count',
        statePer: 'state_percentage',
        lobValue: 'total_state_lob_count',
        lobPer: 'lob_percentage',
        popCount: 'population_count',
        label: 'Coverage',
        // colorAxis: cdcMapLegendHorizontal,
        mapTitle: 'UHC Coverage ',
        colorAxis: colorAxisVulIndex,
        legend: {
          enabled: false,
          title: {
            text: 'UHC Coverage',
          },
        },
      },
    ],
  },
]

export default dataDisplayOptions
