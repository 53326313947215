const typeDisplayOptions = [
  { value: 'home', label: 'My Dashboard' },
  { value: 'rsv', label: 'RSV Incidence' },
  { value: 'flu', label: 'Flu Incidence' },
  { value: 'covid', label: 'COVID Incidence' },
  // { value: 'birth', label: 'Births & Trends' },
  // { value: 'allergies', label: 'Allergies' },
  // { value: 'respiratory', label: 'Respiratory' },
]
export default typeDisplayOptions
