import React, { useState, useEffect } from 'react'
// import moment from 'moment'
// import { useHistory } from 'react-router-dom'
import styles from './UHCCoverageMap.module.scss'
import Icon from '../../../components/Icon'
import CoverageMap from './CoverageMap'

const UHCCoverageMap = () => {
  // const history = useHistory()
  const [btnShowMap, setBtnShowMap] = React.useState(false)
  function showMap() {
    btnShowMap ? setBtnShowMap(false) : setBtnShowMap(true)
  }


  const [getStateJsonFile, setStateJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [getStateJsonFile]);

  return (
    <div className={styles.base}>
      <div className={styles.mapPanel}>
        <div className={styles.header}>
          <div className={styles.para}>
            <p>UHC Coverage </p>
          </div>
          <div className={styles.icon} onClick={showMap}>
            {btnShowMap === true ? (
              <Icon size="18px" type="arrow_up" color="grey" />
            ) : (
              <Icon size="18px" type="arrow_down" color="grey" />
            )}
          </div>
        </div>
        {btnShowMap === true  && getStateJsonFile ? (
          <div className={styles.map}>
            <CoverageMap  stateJson={getStateJsonFile} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default UHCCoverageMap
