function toolTipFormatter() {
  // const pointName =
  //   this.point.properties.cbsa_name === undefined
  //     ? ''
  //     : `/${this.point.properties.cbsa_name}`
  return `<div style="width:110%;background-color:transparent;">
    <div style="width:90%;font-size:14px;
    background-color:transparent;
    color:#424242;padding-left:10px;
    padding-top:10px;padding-right:5px;
    display:inline;">${this.point.name.toUpperCase()} UHC COVERAGE </div>

    <div style="padding-top:10px;display: inline-block;padding-right:10px;">
    <span style="border-radius:50%;
    background-color:${this.color}; 
    display: inline-block;
    width: 10px;
    height: 10px"></span>&nbsp;
    </div>

    <div style="width:110%;background-color:back;color:black;
    font-size:14px;padding-right:10px;">
    <div style="width:90%;
    background-color:transparent;
    color:black;padding-left:10px;font-weight:bold;
    padding-top:10px;padding-right:5px;
    display:inline;">${this.point.value.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}%</div>

    <div style="width:90%;padding-left: 10px;padding-top:10px">

    

    <div style="width:90%;
   padding-top:2px;
    font-size:14px;padding-right:10px;">
    <table>
    <tr>
      <td style="">MR</td>
      <td style="padding-left:15px;">CS</td>
      <td style="padding-left:15px;">EI</td>
    </tr>
    <tr>
      <th style="">${this.point.mr_per.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })}%</th>
      <th style="padding-left:15px;">${this.point.cs_per.toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
        }
      )}%</th>
      <th style="padding-left:15px;">${this.point.ei_per.toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
        }
      )}%</th>
    </tr>

    </table>
    
    </div>
   
    <div style="width:90%;
    padding-top:10px;font-size:12px;
    font-size:12px;padding-right:10px;padding-bottom:10px;">

    State Population :<span style="font-weight:bold;">${
      this.point.population
    }</span>
    </div>
    </div>`
}

export default toolTipFormatter
